import React from 'react'
import { Link } from 'gatsby'
import { login, isAuthenticated } from '../utils/auth'
import { Pane } from 'evergreen-ui'

const NotFound = () => {
  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }

  return (
    <>
      <h1>Page not found</h1>
      <p>
        <Link to="/">Head home</Link>
      </p>
    </>
  )
}

export default NotFound
